<script>
import Vue from "vue";
import appConfig from "@/app.config";
import { with_acrf_token } from '@/methods';
import { FormWizard, TabContent } from "vue-form-wizard";
import {ProductCategory, SubscriptionStatus} from "@/state/helpers";

export default {
  components: {
    FormWizard,
    TabContent,
    EMailChangeForm: () => import("@/components/lazy/account/EMailChangeForm")
  },
  data() {
    return {
      form: null,
      address: null
    };
  },
  created() {

  },
  sockets: {
    email_verified() {
      this.$emit('addressChanged', this.address);
    },
  },
  methods: {
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    mergePartialModels(model, isValid) {
      if(isValid) {
        this.form = Object.assign({}, this.form, model)
      }
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },
    reset() {
      this.form = null;
    },
    completed: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          let that = this;
          let email = this.form.email;
          this.address = email;
          this.$bvModal.hide('changeEmail');

          with_acrf_token().then((r) => {
            let payload = {
              acsrf_token: r.acsrf_token,
              email: email
            };
            fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-email', {
              method: 'POST',
              body: JSON.stringify(payload),
              credentials: 'include'
            })
                .then(response => {
                  if(response.ok || response.status === 400){
                    return response.json();
                  } else {
                    if(response.status === 429) {
                      this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                      });
                    } else
                      throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                  }
                })
                .then(data => {
                  if(data.status) {
                    this.$swal({
                      icon: 'success',
                      text: this.$t('account.email.change.success')
                    });
                  } else {
                    if(data.error === 'taken') {
                      this.$swal({
                        icon: 'error',
                        text: this.$t('account.email.change.error.taken')
                      });
                    } else if(data.error == 'unavailable-address') {
                      this.$swal({
                        icon: 'error',
                        text: this.$t('account.email.change.error.unavailable')
                      });
                    } else {
                      this.$swal({
                        icon: 'error',
                        text: this.$t('account.email.change.error.server')
                      });
                    }
                  }
                })
                .catch(error => {
                  console.log(`[ERROR] ${error}`);
                  this.$swal({
                    icon: 'error',
                    text: this.$t('account.email.change.error.server')
                  });
                });
          }).catch(error => {
            console.log('[ERROR] Failed to request acrf token');
            this.$swal({
              icon: 'error',
              text: this.$t('error.server.generic.message')
            });
          });
          resolve(true);
        }, 1);
      })
    },
  }
};
</script>
<style>
  .wizard-progress-with-circle {
    display: none;
  }
</style>
<template>
    <button
        class="btn btn-primary d-inline-block"
        v-b-modal.changeEmail
        data-toggle="modal"
        data-target="#changeEmail"
        @click="reset()"
        style="min-width: 5rem;"
    >
      <i class="fad fa-edit"></i> {{ $t('account.email.change.button') }}
      <b-modal id="changeEmail" size="md" centered :title="$t('account.email.change.title')" hide-footer>
        <form-wizard
            shape="circle"
            errorColor="#f46a6a"
            color="#4285F4"
            @on-complete="completed"
            :start-index="0"
            :next-button-text="$t('terms.next')"
            :back-button-text="$t('terms.back')"
            :finish-button-text="$t('account.email.change.confirm')"
        >
          <tab-content icon="fas fa-envelope-open" :before-change="()=>validateStep('emailForm')">
            <EMailChangeForm ref="emailForm" @on-validate="mergePartialModels"></EMailChangeForm>
          </tab-content>
        </form-wizard>
      </b-modal>
    </button>
</template>